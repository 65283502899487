<template>
  <v-btn :color="color" width="95%" height="75" class="button mb-3" @click="$emit('click')">
    <v-row class="d-flex justify-center align-center">
      <p class="text mb-0" v-html="text"></p>
      <div class="arrow" v-if="!disableArrow">
        <v-img :src="arrow" max-height="20%"></v-img>
      </div>
    </v-row>
  </v-btn>
</template>

<script>
import arrow from '../../../assets/images/icons/arrow.svg'

export default {
  name: 'SubmitButton',
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#A53100'
    },
    disableArrow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      arrow
    }
  }
}
</script>

<style scoped>
.button {
  border-radius: 10px;
}
.text {
  color: white;
}
.arrow {
  position: absolute;
  right: 7%;
}
</style>
