export default [
  {
    id: 1,
    name: 'New Hires',
    nameFr: 'Nouvelles Recrues',
    description: {
      title: 'Description',
      text: 'New associates are 2 to 3 times more likely to be injured in their first year.',
      textFr: 'Les nouveaux associés sont deux à trois fois plus susceptibles' +
        ' d\'être blessés au cours de leur première année.'
    },
    course: {
      title: 'Course of Action',
      titleFr: 'Mesures à prendre',
      text: '<strong>Engage</strong> with all new associates, give them a safety passport to address the most' +
      ' frequent causes of injury. Periodically follow up with new associates to drive adherence to safety practices.',
      textCanada: '<strong>Engage</strong> with all new associates.' +
        ' Periodically follow up with new associates through observations to drive adherence to safety practices.',
      textFr: '<strong>Engagez-vous</strong> avec tous les nouveaux associés. Effectuez un suivi régulier auprès' +
        ' d’eux par le biais d\'observations pour les inciter à adhérer aux pratiques de sécurité.'
    }
  },
  {
    id: 2,
    name: 'Leadership',
    description: {
      title: 'Description',
      text: 'As the leader, you set the tone in your store. Your commitment to your associates is' +
        ' demonstrated by how you engage with them. Choose to lead with safety in mind.',
      textFr: 'En tant que responsable, vous donnez le ton dans votre magasin.' +
        ' Votre engagement envers vos associés est démontré par la façon dont vous vous comportez avec eux.' +
        ' Choisissez de diriger en mettant la sécurité à l’avant-plan.'
    },
    course: {
      title: 'Course of Action',
      titleFr: 'Mesures à prendre',
      text: 'As a leader, safety starts with you.<br>' +
        '<br>' +
        'Engaging with your associates reinforces:<br>' +
        '<br>' +
        '<strong>Every Second,<br>' +
        'Every Decision,<br>' +
        'Every Day Safe.</strong>',
      textFr: 'En tant que responsable, la sécurité commence par vous!' +
        '<br>' +
        'S’engager avec vos associés renforce: <br>' +
        '<br>' +
        '<strong>Chaque seconde,<br>' +
        'Chaque décision,<br>' +
        'Chaque jour.</strong>'
    }
  },
  {
    id: 3,
    name: 'Overexertion',
    nameFr: 'Surmenage',
    description: {
      title: 'Description',
      text: 'Lifting injuries account for 36% of our total injuries each year.',
      textFr: 'Les blessures liées au levage représentent 36 % de l\'ensemble de nos blessures chaque année.'
    },
    course: {
      title: 'Course of Action',
      titleFr: 'Mesures à prendre',
      text: 'Engage associates to reinforce <strong>Lift it Close to Home</strong>.<br>' +
        '<br>' +
        '<strong>Start Close<br>' +
        'Stay Close<br>' +
        'End Close</strong>',
      textFr: 'Amenez les associés à mettre en application l\'initiative « Soulevez près de vous. »<br>' +
        '<br>' +
        '<strong>Commencez près.<br>' +
        'Restez près.<br>' +
        'Finissez près.</strong>'
    }
  },
  {
    id: 4,
    name: 'Equipment Safety',
    nameFr: 'Sécurité En Matière D’équipement',
    description: {
      title: 'Description',
      text: '8% of all injuries are related to equipment and' +
        ' the most severe injuries involve forklifts.',
      textFr: '8 % de toutes les blessures sont liées à' +
        'l\'équipement et les plus graves impliquent des chariots élévateurs à fourche.'
    },
    course: {
      title: 'Course of Action',
      titleFr: 'Mesures à prendre',
      text: '<strong>Engage</strong> with the operator to ensure that they wear their seatbelt.<br>' +
        '<br>' +
        '<strong>Engage</strong> with the process to make sure the spotter' +
        ' and customer always adhere to the Zone of Safety.<br>' +
        '<br>' +
        '<strong>Ensure</strong> all pallets are properly banded or shrink-wrapped.',
      textFr: '<strong>Intervenez</strong> auprès de l\'opérateur pour vous' +
        ' assurer qu\'il porte sa ceinture de sécurité. <br>' +
        '<br>' +
        '<strong>Engagez-vous</strong> dans le processus pour vous assurer que' +
        ' l\'observateur et le client respectent toujours la zone de sécurité.<br>' +
        '<br>' +
        '<strong>Assurez-vous</strong> que toutes les palettes sont correctement' +
        ' sanglées ou emballées sous film rétractable.'
    }
  },
  {
    id: 5,
    name: 'Overexertion',
    nameFr: 'Surmenage',
    description: {
      title: 'Description',
      text: '5-gallon buckets are a leading cause of overexertion injuries in D24.',
      textFr: 'Les seaux de 5 gallons (19 l) sont l\'une' +
        ' des principales causes de blessures dues à un effort excessif dans le secteur D24.'
    },
    course: {
      title: 'Course of Action',
      titleFr: 'Mesures à prendre',
      text: '<strong>Engage</strong> with your D24 associates to ensure that they’re using the paint cart' +
        ' or paint dolly to move 5-gallon product.',
      textCanada: '<strong>Engage</strong> with your D24 associates to ensure that they’re using the paint cart' +
        ' to move 5-gallon product.',
      textFr: '<strong>Demandez</strong> à vos associés du secteur D24 d’utiliser le' +
        ' chariot de peinture pour déplacer les produits de 5 gallons.'
    }
  },
  {
    id: 6,
    name: 'New Hires',
    nameFr: 'Nouvelles Recrues',
    description: {
      title: 'Description',
      text: '100,000 new associates have been hired for our spring season.' +
        ' Do your New Hires have what they need to be safe?',
      textFr: 'Quelque 100 000 nouveaux associés ont été embauchés pour notre saison printanière.<br>' +
        '<br>' +
        'Vous nouvelles recrues ont-elles ce don’t ells ont besion pour être en sécurité?'
    },
    course: {
      title: 'Course of Action',
      titleFr: 'Mesures à prendre',
      text: 'Equip your New Hires with all the necessary PPE to get their job done safely.<br>' +
        '<br>' +
        '<strong>Engage</strong> with your New Hires to ensure they’re using PPE.',
      textFr: 'Équipez vos nouvelles recrues de tous les ÉPI nécessaires pour accomplir leur travail en toute sécurité.<br>' +
        '<br>' +
        ' <strong>Engagez-vous</strong> que tous les nouveaux associés pour vous assurer qu’ils utilisent des EPI.'
    }
  }
]
