export default {
  en: [
    'Much like you are new to this year’s event, what would you give your new associates?',
    'This is the person responsible for leading Safety in your store.',
    'In D28, lifting this improperly would result in this type of injury.',
    'This is one piece of equipment used to move heavy freight.',
    'The purpose of this tool is to prevent lifting injuries in D24.',
    'These items will significantly reduce the chances of an associate being injured.'
  ],
  fr: [
    'Étant vous-même nouveau à l\'événement de cette année, que donneriez-vous à vos nouveaux associés?',
    'C’est la personne responsable de la sécurité dans votre magasin.',
    'Dans le secteur D28, le fait de soulever incorrectement peut entraîner ce type de blessure.',
    'Voici une pièce d\'équipement utilisée pour déplacer des marchandises lourdes.',
    'L\'objectif de cet outil est de prévenir les blessures dues au levage dans le secteur D24.',
    'Ces articles réduiront considérablement les risques de blessure d\'un associé.'
  ]
}
