<template>
  <v-col class="d-flex flex-column justify-space-around fill-height">
    <v-container class="d-flex justify-center" style="width: 80%; max-width: 400px;">
      <v-img :src="logo" />
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <h3 class="mb-7">Find the 6 items to reveal all<br> panels and a chance to win!</h3>
      <v-col class="login d-flex flex-column align-center justify-center">
        <h3 class="sm-header mt-3 mb-3">Store managers</h3>
        <div style="position: relative; width: 100%" class="d-flex justify-center align-center">
          <input
            v-model="ldap"
            class="ldap-input"
            placeholder="Enter LDAP"
            maxlength="7"
            spellcheck="false"
          >
          <span v-if="error" class="cross-icon">✖</span>
        </div>
        <p class="error-text mt-2">{{error}}⁣</p>
        <IconedButton
          :icon="binoculars"
          text="Start/Continue<br>Your Quest"
          color="#231F20"
          height="100"
          @click="startHandler"
        />
      </v-col>
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <IconedButton
        :icon="smile"
        text="Play for fun!"
        height="90"
        @click="funHandler"
      />
      <IconedButton
        :icon="winner"
        text="Check<br>Leaderboard"
        height="90"
        @click="leaderboardHandler"
      />
    </v-container>
  </v-col>
</template>

<script>
import IconedButton from '../components/ui/buttons/IconedButton'
import logo from '../assets/images/main-logo.svg'
import binoculars from '../assets/images/icons/Binoculars.svg'
import smile from '../assets/images/icons/smile.svg'
import winner from '../assets/images/icons/winner.svg'
import AuthApi from '@/api/auth.api'
import routeNames from '@/router/routeNames'

export default {
  name: 'Home',
  components: {
    IconedButton
  },
  data () {
    return {
      logo,
      binoculars,
      smile,
      winner,
      error: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    ldap: {
      get () {
        return this.$store.getters.ldap
      },
      set (value) {
        this.$store.dispatch('setLdap', value.toUpperCase())
      }
    }
  },
  methods: {
    async startHandler () {
      if (!this.ldap) {
        this.error = 'Please enter your LDAP'
        return
      }
      const response = await AuthApi.validateUser(this.ldap)
      if (response.message) {
        await this.$store.dispatch('setUser', response.data)
        if (this.user.win) await this.$router.push(routeNames.final)
        else await this.$router.push(routeNames.instructions)
      } else {
        this.error = 'Please enter a valid LDAP'
      }
    },
    funHandler () {
      this.$store.dispatch('setUser')
      this.$router.push(routeNames.instructions)
    },
    leaderboardHandler () {
      this.$router.push(routeNames.leaderboard)
    }
  }
}
</script>
<style scoped>
.login {
  background: rgba(165, 49, 0, 0.32);
  border-radius: 10px;
}

.sm-header {
  letter-spacing: 1px;
}

.ldap-input {
  width: 75%;
  height: 50px;
  background-color: white;
  color: #847F7C;
  text-align: center;
  font-family: "Acumin Pro Bold", sans-serif;
  letter-spacing: 2px;
}

.ldap-input::placeholder {
  color: #847F7C;
}

.error-text {
  font-size: 14px;
}
.cross-icon {
  position: absolute;
  top: 13px;
  right: calc(17%);
  color: #DF1003;
}
</style>
