<template>
  <v-col class="rotate d-flex align-center fill-height">
    <v-container class="rotate-inner">
      <div class="rotate-inner__wrapper">
        <div class="rotate-inner__content d-flex flex-column justify-center align-center">
          <div>
            <v-img :src="rotate" width="100%" max-width="500"></v-img>
          </div>
          <p class="rotate-text mt-7">Rotate your phone to<br> view the map!</p>
        </div>
      </div>
    </v-container>
  </v-col>
</template>

<script>
import rotate from '../assets/images/icons/rotate.svg'

export default {
  name: 'BlackRotateScreen',
  data () {
    return {
      rotate
    }
  }
}
</script>

<style scoped>
.rotate {
  background: black;
}

.rotate-inner {
  width: 90%;
  max-width: 400px;
  background: white;
  border-radius: 10px;
  /*aspect-ratio: 1;*/ /* It's working but not everywhere */
}

.rotate-inner__wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  justify-content: center;
}

.rotate-inner__content {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.rotate-text {
  color: black;
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
  text-align: center;
}

@media screen and (orientation: landscape) {
  .rotate {
    display: none !important;
  }
}
</style>
