<template>
  <div class="fill-height">
    <FunModeHeader v-if="!user.sm" />
    <v-col class="d-flex flex-column justify-space-around">
      <PuzzleProgress :progress="progress" />
      <v-container class="d-flex flex-column justify-center align-center mt-1">
        <h2 v-if="progress.length === 0" class="white--text mb-1">Time to search!</h2>
        <h2 v-else-if="progress.length !== 6" class="white--text mb-1">{{progress.length}} out of 6! Keep it going.</h2>
        <div v-else class="d-flex flex-column align-center">
          <h2 class="white--text mb-5">Quest complete!</h2>
          <p class="text-center">Thank you for your participation!
            Safety Takes EveryO.N.E. is designed to focus on the most frequent injury type (Overexertions),
            the most frequently injured associates (New hires within less than one year),
            and our most severe injuries (Equipment related).</p>
          <p v-if="user.sm" class="text-center">As a leader, you define your store's safety culture by continuing to engage with associates.
            You are accountable for promoting and supporting a safe workplace,
            Safety Takes EveryO.N.E. starts with YOU. </p>
        </div>
      </v-container>
      <v-container v-if="progress.length !== 6" class="d-flex flex-column justify-center align-center">
        <IconedButton
          :icon="marker"
          text="View Map for Hints"
          @click="mapClickHandler"
        />
        <IconedButton
          :icon="scan"
          text="Scan Item"
          @click="scanClickHandler"
        />
      </v-container>
      <v-container v-else class="d-flex flex-column justify-center align-center">
        <SubmitButton :text="user.sm ? 'Submit to win!' : 'Complete'" @click="submitWinUser" />
      </v-container>
    </v-col>
  </div>
</template>

<script>
import marker from '../assets/images/icons/Marker.svg'
import scan from '../assets/images/icons/scan.svg'
import IconedButton from '../components/ui/buttons/IconedButton'
import SubmitButton from '../components/ui/buttons/SubmitButton'
import routeNames from '@/router/routeNames'
import PuzzleProgress from '@/components/PuzzleProgress'
import FunModeHeader from '@/components/FunModeHeader'

export default {
  name: 'Progress',
  components: {
    FunModeHeader,
    PuzzleProgress,
    SubmitButton,
    IconedButton
  },
  data () {
    return {
      marker,
      scan
    }
  },
  computed: {
    progress () {
      return this.user.sm
        ? this.$store.getters.user.progress
        : JSON.parse(localStorage.getItem('funProgress') ?? '[]')
    },
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    mapClickHandler () {
      this.$router.push(routeNames.map)
    },
    scanClickHandler () {
      this.$router.push(routeNames.scan)
    },
    submitWinUser () {
      if (this.user.sm) {
        this.$store.dispatch('submitWinUser')
        this.$router.push(routeNames.final)
      } else {
        localStorage.setItem('funProgress', '[]')
        this.$router.push(routeNames.home)
      }
    }
  }
}
</script>

<style scoped>

</style>
