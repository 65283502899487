<template>
  <v-container class="d-flex justify-center">
    <v-img :src="puzzleBase" class="d-flex justify-center align-end" width="90%" max-width="400">
      <div style="margin-bottom: 8.4%; margin-left: 3px;">
        <div v-for="(puzzleRow, i) in puzzle" :key="i" class="puzzle-pieces d-flex" :style="{height: puzzleHeight}">
          <div
            v-for="(puzzleItem, j) in puzzleRow"
            :key="j"
            class="piece d-flex"
            :style="{background: progress.includes(3 * i + j + 1) ? 'transparent' : 'white'}"
          >
            <img
              width="100%"
              v-if="!progress.includes(3 * i + j + 1)"
              :src="puzzleItem"
              alt="piece"
            />
          </div>
        </div>
      </div>
    </v-img>
  </v-container>
</template>

<script>
import piece1 from '../assets/images/puzzles/1.png'
import piece2 from '../assets/images/puzzles/2.png'
import piece3 from '../assets/images/puzzles/3.png'
import piece4 from '../assets/images/puzzles/4.png'
import piece5 from '../assets/images/puzzles/5.png'
import piece6 from '../assets/images/puzzles/6.png'
import puzzleBase from '../assets/images/puzzles/puzzle-base.svg'

export default {
  name: 'PuzzleProgress',
  props: {
    progress: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      puzzle: [[piece1, piece2, piece3], [piece4, piece5, piece6]],
      puzzleBase,
      puzzleHeight: null
    }
  },
  created () {
    // working?
    const width = document.documentElement.clientWidth
    let puzzleBazeWidth
    if (width > 448) puzzleBazeWidth = 400
    else puzzleBazeWidth = width - 48
    const puzzleWidth = puzzleBazeWidth * 252 / 328 / 3 - 3
    this.puzzleHeight = puzzleWidth / 1.67 + 'px'
  }
}
</script>

<style scoped>
.puzzle-pieces {
  margin: auto;
  width: calc((252 / 328) * 100%);
}

.piece {
  width: calc(100% / 3);
  padding: 1px;
}
</style>
