<template>
  <v-expansion-panels accordion multiple :readonly="readonly" tile flat v-model="openedDivisions" class="panels">
    <v-expansion-panel
      v-for="(division, i) in divisions"
      :key="division.name"
    >
      <v-expansion-panel-header class="score-header">
        {{ division.name }}
        <template v-slot:actions>
          <v-img v-if="!readonly" :src="openedDivisions.includes(i) ? minus : plus"></v-img>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table>
          <template #default>
            <tbody>
            <tr
              v-for="(region, index) in division.regions"
              :key="region.name"
            >
              <td class="table-item-number pl-7 pr-4">{{ index + 1 }}</td>
              <td class="table-item-name">{{ region.name }}</td>
              <td class="table-item-progress pl-4">
                <div
                  class="graphic-score"
                  :style="{
                      width: computeWidth(region.percentage),
                      background: computeColor(region.percentage)
                    }">
                </div>
              </td>
              <td class="table-item-percentage pl-4 pr-4">{{ region.percentage }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import plus from '@/assets/images/icons/plus.svg'
import minus from '@/assets/images/icons/minus.svg'
import getLeaderboardColor from '@/utils/getLeaderboardColor'

export default {
  name: 'LeaderboardTable',
  props: {
    divisions: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      plus,
      minus,
      connection: null,
      openedDivisions: this.readonly ? [0, 1, 2, 3, 4] : []
    }
  },
  methods: {
    computeWidth (percentage) {
      const percentageWidth = percentage.split('%')[0] / 100
      return percentageWidth * 90 + 'px'
    },
    computeColor (percentage) {
      const percentageWidth = percentage.split('%')[0]
      return getLeaderboardColor(percentageWidth)
    }
  }
}
</script>

<style scoped>
.panels {
  width: 95%;
}

.score-header {
  background: rgba(229, 229, 229, 0.44);
  border-bottom: 2px solid white;
  color: #4F4E4E;
  font-size: 18px;
  font-weight: 900;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0;
}

td {
  padding: 0 !important;
  vertical-align: middle;
}

.table-item-number {
  width: 0;
  font-weight: 900;
  color: #4F4E4E;
}

.table-item-name {
  width: 40%;
  color: #4F4E4E;
  font-weight: 900;
}

.table-item-progress {
  width: 35%;
}

.graphic-score {
  height: 13px;
  min-width: 15px;
  border-radius: 9px;
}

.table-item-percentage {
  width: 10%;
  font-size: 18px !important;
  color: #847F7C;
}
</style>
