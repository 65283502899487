<template>
  <v-col class="d-flex flex-column justify-space-around fill-height">
    <PuzzleProgress :progress="user.progress" />
    <v-container class="d-flex flex-column justify-center align-center">
      <h2 class="white--text mt-2 mb-0">You're in!</h2>
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <p class="text-center">Your entry has been submitted and the top Region from each Division
        will receive STE scratchers. In addition to these top winners,
        there will be bonus winners selected at random from each Region.</p>
      <p class="text-center">Click below to see how your Region stacks up against others!</p>
      <IconedButton
        :icon="winner"
        text="Check <br> Leaderboard"
        @click="leaderboardClickHandler"
      />
    </v-container>
  </v-col>
</template>

<script>
import winner from '@/assets/images/icons/winner.svg'
import IconedButton from '@/components/ui/buttons/IconedButton'
import routeNames from '@/router/routeNames'
import PuzzleProgress from '@/components/PuzzleProgress'

export default {
  name: 'Final',
  components: {
    IconedButton,
    PuzzleProgress
  },
  data () {
    return {
      winner
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    leaderboardClickHandler () {
      this.$router.push(routeNames.leaderboard)
    }
  }
}
</script>

<style scoped>

</style>
