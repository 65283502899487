export default function getLeaderboardColor (percentage) {
  switch (true) {
    case percentage <= 10:
      return '#ffe9af'
    case percentage <= 20:
      return '#ffe195'
    case percentage <= 30:
      return '#ffe08e'
    case percentage <= 40:
      return '#fdcb74'
    case percentage <= 50:
      return '#fbb85d'
    case percentage <= 60:
      return '#f9a446'
    case percentage <= 70:
      return '#f79435'
    case percentage <= 80:
      return '#e7731a'
    case percentage <= 90:
      return '#c14e0b'
    default:
      return '#a53100'
  }
}
