export default {
  home: '/',
  instructions: '/instructions',
  progress: '/progress',
  final: '/final',
  map: '/progress/map',
  scan: '/progress/scan',
  productBase: '/progress/scan/product',
  product: '/progress/scan/product/:id',
  leaderboard: '/leaderboard',

  admin: '/admin',
  monitor: '/admin/monitor',
  monitorLeaderboard: '/admin/monitor/leaderboard'
}
