<template>
  <v-img :src="src" width="8%" :style="position" @click="$emit('click', id)"></v-img>
</template>

<script>
export default {
  name: 'MarkerItem',
  props: {
    id: {
      type: Number,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    left: {
      type: String,
      default: '0'
    },
    top: {
      type: String,
      default: '0'
    }
  },
  computed: {
    position () {
      return {
        position: 'absolute',
        top: this.top + '%',
        left: this.left + '%',
        cursor: 'pointer'
      }
    }
  }
}
</script>

<style scoped>

</style>
