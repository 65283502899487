<template>
  <v-btn :color="color" width="95%" :height="height" class="button mb-3" @click="$emit('click')">
    <v-row class="d-flex justify-center align-center">
      <div><v-img :src="icon" max-height="25%" /></div>
      <p class="text mb-0 ml-5" v-html="text" />
    </v-row>
  </v-btn>
</template>

<script>
export default {
  name: 'IconedButton',
  props: {
    icon: {
      type: String
    },
    text: {
      type: String
    },
    height: {
      type: String,
      default: '135'
    },
    color: {
      type: String,
      default: '#A53100'
    }
  }
}
</script>

<style scoped>
.text {
  text-transform: none;
  color: white;
  text-align: left
}
.button {
  border-radius: 10px;
}
</style>
