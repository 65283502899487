<template>
  <div class="fill-height">
    <FunModalAsker v-if="!user.sm" />
    <FunModeHeader v-if="!user.sm" mb="mb-0" />
    <v-col class="main d-flex flex-column justify-space-between fill-height">
      <v-container class="d-flex flex-column justify-center align-center">
        <v-img :src="productImage" class="rounded-lg" width="95%" />
        <v-col class="d-flex flex-column align-center pa-0 pt-6" style="width: 85%">
          <div class="d-flex align-center align-self-start mb-3">
            <div class="product-id"><span class="pt-2">{{product.id}}</span></div>
            <div>
              <p class="product-name mb-0 ml-3">{{product.name}}</p>
              <p v-if="french && product.nameFr" class="product-name product-name-fr mb-0 ml-3">
                {{product.nameFr}}
              </p>
            </div>
          </div>
          <v-expansion-panels accordion multiple tile flat v-model="openedTabs" class="panels">
            <v-expansion-panel
              class="panel"
              v-for="(panel, i) in ['description', 'course']"
              :key="i"
            >
              <v-expansion-panel-header class="panel-header pa-0">
                <div class="d-flex flex-column pt-3 pb-3">
                  <span>{{ product[panel].title }}</span>
                  <span v-if="french && product[panel].titleFr" class="panel-subtitle mt-1">
                    {{product[panel].titleFr}}
                  </span>
                </div>
                <template v-slot:actions>
                  <v-img :src="openedTabs.includes(i) ? minus : plus" />
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <p v-if="french && product[panel].textCanada" class="panel-content" v-html="product[panel].textCanada" />
                <p v-else class="panel-content" v-html="product[panel].text" :class="{'mb-0': !french}" />
                <p v-if="french" class="panel-content mb-0 font-italic" v-html="product[panel].textFr" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-container>
      <v-container class="d-flex flex-column justify-center align-center">
        <SubmitButton text="Submit item" @click="submitHandler" />
      </v-container>
    </v-col>
  </div>
</template>

<script>
import SubmitButton from '@/components/ui/buttons/SubmitButton'
import FunModeHeader from '@/components/FunModeHeader'
import FunModalAsker from '@/components/FunModalAsker'
import plus from '../assets/images/icons/plus.svg'
import minus from '../assets/images/icons/minus.svg'
import product1 from '../assets/images/products/1.jpg'
import product2 from '../assets/images/products/2.jpg'
import product3 from '../assets/images/products/3.jpg'
import product4 from '../assets/images/products/4.jpg'
import product5 from '../assets/images/products/5.jpg'
import product6 from '../assets/images/products/6.jpg'
import products from '@/data/products'
import routeNames from '@/router/routeNames'
import AuthApi from '@/api/auth.api'

export default {
  name: 'Product',
  components: { SubmitButton, FunModeHeader, FunModalAsker },
  data () {
    return {
      plus,
      minus,
      openedTabs: [],
      productImages: [product1, product2, product3, product4, product5, product6],
      product: {
        id: 0,
        name: '',
        description: {
          title: '',
          text: ''
        },
        course: {
          title: '',
          text: ''
        }
      },
      french: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    productImage () {
      return this.productImages[this.product.id - 1]
    }
  },
  methods: {
    submitHandler () {
      const productId = this.product.id
      if (productId === 0) return
      if (this.user.sm) {
        if (!this.user.progress.includes(productId)) {
          this.$store.dispatch('updateUserProgress', productId)
        }
      } else {
        const funProgress = JSON.parse(localStorage.getItem('funProgress') ?? '[]')
        if (!funProgress.includes(productId)) {
          funProgress.push(productId)
          localStorage.setItem('funProgress', JSON.stringify(funProgress))
        }
      }
      this.$router.push(routeNames.progress)
    }
  },
  async created () {
    if (this.user.ldap) {
      const response = await AuthApi.validateUser(this.user.ldap)
      if (!response.error) {
        await this.$store.dispatch('setUser', response.data)
        if (this.user.division === 'Canada') {
          this.french = true
        }
      }
    }
    const hashedIds = ['806b80c9', '91aa2346', 'a400c7f3', 'b803eff9', 'c947127f', 'db7fb6f0']
    const hashedProductId = this.$route.params.id
    const productId = hashedIds.indexOf(hashedProductId)
    if (productId !== -1) {
      this.product = products[productId]
    }
  }
}
</script>

<style scoped>
.main {
  background: white;
}

.product-id {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: black;
  font-weight: bold;
  color: white;
  font-size: 36px;
}

.product-name {
  color: black;
  font-size: 22px;
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
}

.product-name-fr {
  font-size: 15px;
  font-style: italic;
}

.panel {
  border-bottom: 1px solid black;
}

.panel-header {
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
  font-size: 18px;
}

.panel-subtitle {
  font-style: italic;
}

.panel-content {
  font-size: 16px;
  color: #4F4E4E;
}

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 0 15px;
}
</style>
