import axios from 'axios'
import urlConfig from '@/api/urlConfig'

export default class AuthApi {
  static async validateUser (ldap) {
    const config = {
      method: 'get',
      url: `${urlConfig.validateUser}/${ldap}`,
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }
}
