<template>
  <v-col class="d-flex flex-column justify-space-between fill-height">
    <v-container class="d-flex flex-column justify-start align-center">
      <h2 class="mt-5 mb-10">Leaderboard</h2>
      <LeaderboardTable :divisions="divisions" />
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <IconedButton
        :icon="binoculars"
        text="Start/Continue<br>Your Quest"
        color="#231F20"
        @click="startQuestHandler"
      />
      <SubmitButton text="Exit" @click="exitButtonHandler" />
    </v-container>
  </v-col>
</template>

<script>
import IconedButton from '../components/ui/buttons/IconedButton'
import LeaderboardTable from '@/components/LeaderboardTable'
import binoculars from '../assets/images/icons/Binoculars.svg'
import routeNames from '@/router/routeNames'
import AuthApi from '@/api/auth.api'
import LeaderboardApi from '@/api/leaderboard.api'
import SubmitButton from '@/components/ui/buttons/SubmitButton'

export default {
  name: 'Leaderboard',
  components: {
    SubmitButton,
    LeaderboardTable,
    IconedButton
  },
  data () {
    return {
      binoculars,
      divisions: []
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    async startQuestHandler () {
      if (!this.user.sm) {
        if (!this.user.ldap) {
          await this.$router.push(routeNames.home)
          return
        }
        const response = await AuthApi.validateUser(this.user.ldap)
        if (response.error) await this.$router.push(routeNames.home)
        else await this.$router.push(routeNames.progress)
      } else await this.$router.push(routeNames.progress)
    },
    exitButtonHandler () {
      this.$router.push(routeNames.home)
    }
  },
  async created () {
    const response = await LeaderboardApi.getStatistic()
    if (!response.error) {
      this.divisions = response.data
    }
  }
}
</script>

<style scoped>

</style>
