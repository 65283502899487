<template>
  <div class="wrapper">
    <p class="pa-2" :class="mb">Fun mode. No entry to contest.</p>
  </div>
</template>

<script>
export default {
  name: 'FunModeHeader',
  props: {
    mb: {
      type: String,
      default: 'mb-5'
    }
  }
}
</script>

<style scoped>
.wrapper {
  background-color: #FF7500;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
</style>
