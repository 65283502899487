import UserApi from '@/api/user.api'

export default {
  state: {
    user: {
      ldap: localStorage.getItem('ldap') ?? '',
      sm: '',
      division: '',
      region: '',
      language: 'en',
      progress: [],
      win: false
    }
  },
  getters: {
    user: state => state.user,
    ldap: state => state.user.ldap
  },
  mutations: {
    setUser (state, user) {
      if (user) {
        state.user = user
      } else {
        state.user = {
          ldap: localStorage.getItem('ldap') ?? '',
          sm: '',
          division: '',
          region: '',
          language: 'en',
          progress: [],
          win: false
        }
      }
    },
    setLdap (state, ldap) {
      state.user.ldap = ldap
      localStorage.setItem('ldap', ldap)
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    setLdap ({ commit }, ldap) {
      commit('setLdap', ldap)
    },
    async updateUserProgress ({ state }, productId) {
      const response = await UserApi.updateProgress(state.user.ldap, productId)
      if (!response.error) state.user.progress.push(productId)
    },
    async submitWinUser ({ state }) {
      const response = await UserApi.submitWin(state.user.ldap)
      if (!response.error) state.user.win = true
    }
  }
}
