import axios from 'axios'
import urlConfig from '@/api/urlConfig'

export default class UserApi {
  static async updateProgress (ldap, productId) {
    const config = {
      method: 'put',
      url: `${urlConfig.updateProgress}`,
      data: { ldap, productId },
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }

  static async submitWin (ldap) {
    const config = {
      method: 'put',
      url: `${urlConfig.submitWin}`,
      data: { ldap },
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }
}
