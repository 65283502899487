<template>
  <v-dialog
    v-model="modal"
    width="80%"
    max-width="500"
    content-class="rounded-lg"
    persistent
  >
    <v-card class="card d-flex flex-column align-center justify-center pt-10 pb-10">
      <v-img :src="alert" width="50" contain />
      <p class="dialog-text mt-7">You are entering fun mode.</p>
      <p class="text-center mt-3">This mode does not participate in the leaderboard or receiving STE scratchers.</p>
      <v-col class="action-buttons d-flex flex-column align-center">
        <SubmitButton text="Continue" @click="continueHandler" class="fun-mode" />
        <SubmitButton text="Enter LDAP" color="#231F20" disable-arrow @click="returnHandler" />
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import alert from '../assets/images/icons/alert.svg'
import SubmitButton from '@/components/ui/buttons/SubmitButton'
import routeNames from '@/router/routeNames'

export default {
  name: 'InstructionsModal',
  components: { SubmitButton },
  data () {
    return {
      modal: true,
      alert
    }
  },
  methods: {
    continueHandler () {
      this.modal = false
    },
    returnHandler () {
      this.$router.push(routeNames.home)
    }
  }
}
</script>

<style scoped>
.card {
  font-family: "Acumin Pro", sans-serif;
}

.dialog-text {
  width: 75%;
  font-size: 18px;
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
  text-align: center;
}

.action-buttons {
  width: 95%;
}
</style>
