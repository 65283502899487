import axios from 'axios'
import urlConfig from '@/api/urlConfig'

export default class LeaderboardApi {
  static async getStatistic () {
    const config = {
      method: 'get',
      url: `${urlConfig.getStatistic}`,
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }

  static async getReport () {
    const config = {
      method: 'get',
      url: `${urlConfig.getReport}`,
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }

  static async clearLeaderboard () {
    const config = {
      method: 'get',
      url: `${urlConfig.clearLeaderboard}`,
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }
}
